import React from 'react'
import LOGO from "./assets/img/favicon.png";


export default function Loading() {
  return (
    <>
    <div style={{textAlign: "center",}}><img width={300} height={300} class='align-self-center' src={LOGO} alt="" /></div>

    

</>
  )
}
